import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, switchMap, tap} from "rxjs";
import {SessionService} from "../state/session.service";
import {AppService} from "../app.service";
import {UserService} from "../user-list/service/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private readonly httpClient: HttpClient,
              private readonly appService: AppService,
              private readonly userService: UserService,
              private readonly session: SessionService) {
  }

  login(req: { username: string, password: string }) {
    return this.httpClient.post<{ token: string }>(environment.API_URL + '/api/login', req)
      .pipe(
        tap((user) => this.session.setAuthToken(user.token)),
        switchMap(() => this.appService.init()),
        map(() => true)
      );
  }

  refreshToken() {
    this.httpClient.get<{ username: string, token: string }>(environment.API_URL + '/api/login/refresh')
      .pipe(
        tap((user) => this.session.setAuthToken(user.token)),
        switchMap(() => this.userService.fetchUser()),
        switchMap(() => this.appService.init()),
        map(() => true)
      ).subscribe();
  }
}
