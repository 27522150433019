import {Injectable} from '@angular/core';
import {PromptService} from "./prompt-list/prompt.service";
import {forkJoin} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private readonly promptService: PromptService) {
  }

  init() {
    return forkJoin({
      tags: this.promptService.fetchTags(),
    });
  }
}
